import { Language } from 'src/models/language.model';
/* eslint-disable max-len */
// spell-checker: disable
export const FRANCAIS: Language = {
      /* GENERAL TERMS*/
  Activate: 'Activer',
  Auth_Error: 'L\'autorisation a échoué: vous n\'êtes pas autorisé à utiliser ce service!',
  Cancel: 'Annuler',
  Compromised_VSC: 'Signaler comme compromis',
  Here: 'ici',
  Order: 'Commander',
  Proxy_Error: 'L\'authentification a échoué: assurez-vous que vous accédez à ce service par le réseau interne de l\'entreprise.',
  Redirecting: 'Vous rediriger vers la page de démarrage dans ',
  Seconds: ' secondes',
  Start: 'Accueil',
  Step: 'Étape',
  Support: 'Wiki',
  Token_Expiration: 'Le jeton a expiré, veuillez recharger la page pour refaire l\'authentification.',
  Token_Process_Error: 'Votre demande n\'a pas pu être traitée, veuillez contacter le support.',
  Polling_stopped: 'La mise à jour de la page est interrompue, veuillez la recharger pour continuer la mise à jour: ',
  Title_modifier: 'Portail de gestion',
  Renew: 'Renouveler',
  No_Email: 'Vos données utilisateur ne contiennent pas d\'adresse e-mail. Votre commande ne peut pas être traitée, veuillez contacter le service d\'assistance!',
  Tenant_Unknown: 'Désolé, nous n\'avons pas pu vous associer à votre service: veuillez ouvrir un billet de service auprès de votre soutien local.',
  IDP_Error: 'Erreur d\'authentification: veuillez contacter le support.',
  BackendUrl_Error: 'Erreur de service, veuillez contacter le support.',


  /* ORDER PAGE */
  Order_checkbox: 'Je confirme que je veux commander une carte à puce virtuelle.',
  Order_checkboxLabel: 'Commande',
  Order_confirmOrder: 'Confirmer la commande',
  Order_costs: '€ par mois',
  Order_description_1: 'Des renseignements supplémentaires peuvent être trouvés',
  Order_email_1: 'Votre commande de carte à puce virtuelle est en cours de traitement, veuillez patienter jusqu\'à ce que l\'activation soit disponible. Si c\'est votre première fois, veuillez suivre les instructions qui vous ont été envoyées par e-mail',
  Order_intro: 'La carte à puce virtuelle ne prend actuellement en charge que les clients Windows gérés.',
  Order_software: 'Recevoir un courriel et installer le logiciel',
  Order_processing: 'Votre commande de carte à puce virtuelle est en cours de traitement.',
  Order_priceFetchError: 'Erreur lors de la récupération des prix, veuillez contacter le support.',
  Order_CCA: 'facturé par la répartition des coûts de l\'entreprise',

  /* ACTIVATE PAGE */
  Activation_active: 'L\'activation est en cours de traitement',
  Activation_button: 'Entrez le NIP et activez',
  Activation_confirm: 'Confirmer l\'activation',
  Activation_confirmation: 'Nous traitons votre demande d\'activation.',
  Activation_main: 'Vous devez choisir votre propre NIP à 8 chiffres lors de l\'activation de la carte à puce virtuelle.',
  Activation_support: 'En cas de problème, veuillez contacter le support.',
  Activation_error: 'Une erreur s\'est produite, impossible de traiter l\'activation du jeton.',
  // temporary entries for modal
  Cancel_Activate: 'ANNULER',
  Enter_PIN: 'Veuillez entrer un NIP numérique à 8 chiffres',
  Excessive_Length: 'Longueur excessive: le NIP doit comporter 8 chiffres!',
  Input_Mismatch: 'Les NIP ne correspondent pas!',
  Insufficient_Length: 'Longueur insuffisante: le NIP doit comporter 8 chiffres!',
  Submit_Activate: 'SOUMETTRE',
  Token_PIN_Input: 'Saisie du NIP',

  /* RENEW PAGE */
  Renew_processing: 'Le renouvellement est en cours de traitement',
  Renew_process_error: 'Une erreur s\'est produite, impossible de traiter le renouvellement de la carte à puce virtuelle. Essayez de nouveau plus tard.',
  Renew_confirm: 'Confirmer le renouvellement',
  Renew_intro: 'Votre carte à puce virtuelle sera renouvelée',
  Renew_description: 'Vous recevrez de nouveaux certificats sur une nouvelle carte à puce virtuelle. Les anciens certificats et l\'ancienne carte à puce seront supprimés (révoqués). Les nouveaux certificats seront publiés dans le Siemens Corprorate Directory (SCD) dans les 24 heures.',
  // Renew_description2: 'Avec le renouvellement, vous recevrez des courriels confirmant l\'émission et la révocation.',
  Renew_checkboxLabel: 'Je confirme que je veux renouveler ma carte à puce virtuelle',
  Renew_button: 'Renouveler',
  Renew_post_description: 'Dans quelques secondes, vous serez redirigé vers la page de démarrage et averti une fois le processus terminé. Ensuite, vous devez activer votre nouvelle carte à puce virtuelle.',

  /* CANCELATION PAGE */
  Cancel_button: 'Annuler',
  Cancel_cancelled: 'L\'annulation est en cours de traitement',
  Cancel_charges: 'Vos frais prendront fin avec le mois en cours.',
  Cancel_confirm: 'Confirmer l\'annulation',
  Cancel_confirmation: 'Je confirme que je veux annuler ma carte à puce virtuelle',
  Cancel_main: 'Ça annule votre carte à puce virtuelle.',
  Cancel_second: 'Votre carte à puce virtuelle sera annulée.',
  Cancel_confirmed: 'Annulation confirmée',

  /* COMPROMISED VSC */
  Compromised_button: 'Invalider',
  Compromised_confirmation: 'Je confirme que je souhaite invalider (révoquer) ma carte à puce virtuelle compromise.',
  Compromised_heading: 'Signaler comme compromis',
  Compromised_main: 'Avec votre confirmation, votre carte à puce virtuelle sera désactivée et vos certificats invalidés (révoqués).',
  Compromised_tabs: [
    'YTu ne pourras plus l\'utiliser.',
    'Si vous avez une carte d\'identité d\'entreprise et que vous voulez toujours l\'utiliser, renouvelez vos certificats',
    'Vous devez aviser votre RSSI de toute utilisation abusive de votre carte.'
  ],
  Compromised_cancelled: 'L\'invalidation est en cours de traitement',
  Compromised_main2: `Votre carte à puce virtuelle sera désactivée et tous les certificats invalidés (révoqués).`,
  CISO: 'Liste de contacts mondiaux en matière de cybersécurité',

  /* START PAGE */
  Start_box_activate_now: 'Activer maintenant',
  Start_box_activate: 'Prêt pour l\'activation',
  Start_box_active: 'Votre VSC est actif',
  Start_box_orderState: 'Admissible à la commande',
  Start_box_order_now: 'Commander maintenant',
  Start_box_processing: 'Votre demande est en cours de traitement',
  Start_box_error: 'Veuillez contacter le soutien',

  /* FOOTER */
  Footer_Cookie_Notice: 'Avis sur les témoins',
  Footer_Corporate_Information: 'Renseignements sur l\'entreprise',
  Footer_Digital_ID: 'Identifiant numérique',
  Footer_Privacy_Notice: 'Avis de confidentialité',
  Footer_Terms_of_Use: 'Conditions d\'utilisation',

  /* LOGOUT */
  Logout_heading: 'Carte à puce virtuelle: déconnectée',
  Logout_main: 'Vous avez été déconnecté avec succès.',

  /* HEADER */
  Header_Modal_Close: 'Fermer',
  Header_Modal_Description: 'Sélectionnez votre langue préférée.',
  Header_Modal_Title: 'Sélection de la langue'
};
