import { Language } from 'src/models/language.model';
// eslint-disable max-len

export const DEUTSCH: Language = {
  /* ALLGEMEINE GESCHÄFTSBEDINGUNGEN*/
  Activate: 'Aktivieren',
  Auth_Error: 'Autorisierung fehlgeschlagen: Sie dürfen diesen Dienst nicht nutzen!',
  Cancel: 'Stornieren',
  Compromised_VSC: 'Als kompromittiert melden',
  Here: 'hier',
  Order: 'Bestellen',
  Proxy_Error: 'Authentifizierung fehlgeschlagen: Bitte stellen Sie sicher, dass Sie über das interne Netzwerk des Unternehmens auf diesen Dienst zugreifen.',
  Redirecting: 'Weiterleitung zur Startseite in',
  Seconds: 'Sekunden',
  Start: 'Start',
  Step: 'Schritt',
  Support: 'Wiki',
  Token_Expiration: 'Token abgelaufen, bitte laden Sie die Seite neu, um die Authentifizierung erneut durchzuführen.',
  Token_Process_Error: 'Ihre Anfrage konnte nicht verarbeitet werden. Bitte setzen Sie sich mit dem Support in Verbindung.',
  Polling_stopped: 'Seitenaktualisierung angehalten, bitte laden Sie die Seite neu:',
  Title_modifier: 'Managementportal',
  Renew: 'Erneuern',
  No_Email: 'Ihre Nutzerdaten enthalten keine E-Mail-Adresse. Ihre Bestellung kann daher nicht bearbeitet werden, bitte wenden Sie sich an den Service Desk!',
  Tenant_Unknown: 'Tut uns leid, wir konnten Sie nicht zuordnen: bitte machen sie ein Ticket bei Ihrem lokalen Support auf.',
  IDP_Error: 'Authentifizierung-Fehler: bitte kontaktieren Sie den Support.',
  BackendUrl_Error: 'Service-Fehler, bitte kontaktieren Sie den Support.',

  /* SEITE BESTELLEN */
  Order_checkbox: 'Ich bestätige, dass ich eine Virtual Smart Card bestellen möchte.',
  Order_checkboxLabel: 'Bestellen',
  Order_confirmOrder: 'Bestellung bestätigen',
  Order_costs: '€ pro Monat',
  Order_description_1: 'Weitere Informationen finden Sie',
  Order_email_1: 'Ihre Virtual Smart Card Bestellung wird bearbeitet. Bitte warten Sie, bis die Aktivierung verfügbar ist. Wenn dies Ihr erstes Mal ist, folgen Sie bitte den Anweisungen, die Sie per E-Mail erhalten.',
  Order_intro: 'Die Virtual Smart Card unterstützt derzeit nur verwaltete Windows Clients.',
  Order_software: 'E-Mail erhalten und Software installieren',
  Order_processing: 'Ihre Virtual Smart Card Bestellung wird bearbeitet.',
  Order_priceFetchError: 'Fehler beim Abrufen der Preise, bitte kontaktieren Sie den Support.',
  Order_CCA: 'über die betriebliche Kostenverrechnung abgerechnet',

  /* SEITE AKTIVIEREN */
  Activation_active: 'Aktivierung läuft',
  Activation_button: 'PIN eingeben und aktivieren',
  Activation_confirm: 'Aktivierung bestätigen',
  Activation_confirmation: 'Wir bearbeiten Ihre Aktivierungsanfrage.',
  Activation_main: 'Sie müssen bei der Aktivierung der Virtual Smart Card Ihre eigene 8-stellige PIN eingeben.',
  Activation_support: 'Bei Problemen wenden Sie sich bitte an den Support.',
  Activation_error: 'Es ist ein Fehler aufgetreten, die Token-Aktivierung konnte nicht verarbeitet werden. ',
  // temporäre Einträge für modal
  Cancel_Activate: 'ABBRECHEN',
  Enter_PIN: 'Bitte geben Sie eine 8-stellige numerische PIN ein',
  Excessive_Length: 'Übermäßige Länge: PIN muss 8 Ziffern lang sein!',
  Input_Mismatch: 'PINs stimmen nicht überein!',
  Insufficient_Length: 'Unzureichende Länge: PIN muss 8 Ziffern lang sein!',
  Submit_Activate: 'SENDEN',
  Token_PIN_Input: 'PIN-Eingabe',

  /* SEITE ERNEUERN */
  Renew_processing: 'Erneuerung läuft',
  Renew_process_error: 'Fehler bei der Erneuerung Ihrer Virtual Smart Card, bitte versuchen Sie es später noch einmal.',
  Renew_confirm: 'Erneuerung bestätigen',
  Renew_intro: 'Ihre Virtual Smart Card wird erneuert.',
  Renew_description: 'Sie erhalten neue Zertifikate auf einer neuen virtuellen smart card. Die alten Zertifikate sowie die alte virtuelle smart card werden gelöscht (revoziert). Die neuen Zertifikate werden innerhalb von 24 Stunden im Siemens Corporate Directory (SCD) veröffentlicht.',
  // Renew_description2: 'Im Zuge der Erneuerung erhalten Sie Emails, die die Erstellung und Revozierung bestätigen.',
  Renew_checkboxLabel: 'Ich bestätige, dass ich meine Virtual Smart Card erneuern möchte',
  Renew_button: 'Erneuern',
  Renew_post_description: 'Sie werden in wenigen Sekunden auf die Startseite weitergeleitet und benachrichtigt sobald der Prozess abgeschossen ist. Im Anschluss müssen Sie die neue Virtual Smart Card aktivieren.',

  /* STORNIERUNG SEITE */
  Cancel_button: 'Stornieren',
  Cancel_cancelled: 'Stornierung läuft',
  Cancel_charges: 'Die Verrechnung endet mit dem aktuellen Monat',
  Cancel_confirm: 'Stornierung bestätigen',
  Cancel_confirmation: 'Ich bestätige, dass ich meine Virtual Smart Card stornieren möchte',
  Cancel_main: 'Dadurch wird Ihre Virtual Smart Card storniert.',
  Cancel_second: 'Ihre Virtual Smart Card wird storniert.',
  Cancel_confirmed: 'Stornierung bestätigt',

  /* VSC KOMPROMITTIERT */
  Compromised_button: 'Revozieren',
  Compromised_confirmation: 'Ich will meine kompromittierte Virtual Smart Card deaktivieren (revozieren).',
  Compromised_heading: 'Als kompromittiert melden',
  Compromised_main: 'Mit Ihrer Bestätigung wird Ihre Virtual Smart Card deaktiviert und Ihre Zertifikate annulliert (revoziert).',
  Compromised_tabs: [
    'Sie können diese dann nicht mehr verwenden.',
    'Falls Sie einen Firmenausweis besitzen und diesen weiterhin verwenden möchten, erneuern Sie Ihre Zertifikate',
    'Sie müssen Ihren CISO über jeden möglichen Missbrauch Ihrer Karte informieren.'
  ],
  Compromised_cancelled: 'Deaktivierung läuft',
  Compromised_main2: 'Ihre Virtual Smart Card wird deaktiviert und alle Zertifikate werden annulliert (revoziert).',
  CISO: 'Globale CyberSecurity-Kontaktliste',

  /* STARTSEITE */
  Start_box_activate_now: 'Jetzt aktivieren',
  Start_box_activate: 'Bereit zur Aktivierung',
  Start_box_active: 'Ihre VSC ist aktiv',
  Start_box_orderState: 'Berechtigt zu bestellen',
  Start_box_order_now: 'Jetzt bestellen',
  Start_box_processing: 'Ihre Anfrage wird bearbeitet',
  Start_box_error: 'Bitte Support anfragen',

  /* FUSSZEILE */
  Footer_Cookie_Notice: 'Cookie-Hinweis',
  Footer_Corporate_Information: 'Unternehmen',
  Footer_Digital_ID: 'Digitale ID',
  Footer_Privacy_Notice: 'Datenschutzerklärung',
  Footer_Terms_of_Use: 'Nutzungsbedingungen',

  /* AUSLOGGEN */
  Logout_heading: 'Virtual Smart Card: Abgemeldet',
  Logout_main: 'Sie wurden erfolgreich abgemeldet.',

  /* HEADER */
  Header_Modal_Close: 'Schließen',
  Header_Modal_Description: 'Bitte wählen Sie Ihre bevorzugte Sprache aus.',
  Header_Modal_Title: 'Sprachauswahl',
};
