import { createAction, props } from '@ngrx/store';
import { OrderState } from 'src/helperFunctions';
import { TokenRequest } from 'src/models/token-request.model';

export const setAuthDone = createAction(
  'Set Login value',
  props<{ value: boolean }>()
);

export const setOrderState = createAction(
  'Set Start state value',
  props<{ value: OrderState }>()
);

export const setOrderStep = createAction(
  'Set Order step value',
  props<{ value: number }>()
);

export const setActivateStep = createAction(
  'Set Activate step value',
  props<{ value: number }>()
);

export const setCancelStep = createAction(
  'Set Cancel Step value',
  props<{ value: number }>()
);

export const setCompromisedStep = createAction(
  'Set Compromised Step value',
  props<{ value: number }>()
);

export const setLanguage = createAction(
  'Set Language value',
  props<{ setting: string }>()
);

export const setCost = createAction(
  'Set Cost value',
  props<{ department: string; value: string }>()
);

export const setLanguageIcon = createAction(
  'Set Language Icon value',
  props<{ value: string }>()
);

export const setCompromised = createAction(
  'Set Compromised value',
  props<{ value: boolean }>()
);

// sets the authorization code to the value of the code parameter
export const setAuthCode = createAction(
  'Set Authorization Code',
  props<{ value: string }>()
);

/**
 * This function takes an object as an argument and sets the value of the tokenRequests
 * variable to the value of the object.
 * @param {object} value - The value to set the token requests to.
 */
export const setTokenRequest = createAction(
  'Set Token Requests',
  props<{ value: TokenRequest[] }>()
);

/**
 * `setToken` is a function that takes a string as an argument and sets the value of the `token`
 * variable to the value of the argument
 * @param {string} value - The value to set the token to.
 */
export const setToken = createAction('Set Token', props<{ value: string }>());

// sets the PIN
export const setPIN = createAction('Set Pin', props<{ value: string }>());

export const setDuration = createAction(
  'Set duration',
  props<{value: number}>()
);

export const setPollingTimeOut = createAction(
  'Set polling timeout',
  props<{value: number}>()
);

export const setPollingTimeOutDuration = createAction(
  'Set polling timeout duration',
  props<{value: number}>()
);

export const setPageExpired = createAction(
  'Set page expired',
  props<{value: boolean}>()
);

export const setGID = createAction(
  'Set GID',
  props<{value: string}>()
);

export const setDeactivate = createAction(
  'Set Deactivate',
  props<{value: boolean}>()
);

export const setRenewStep = createAction(
  'Set Renew Step',
  props<{value: number}>()
);

// TODO: hide/show renewToken
export const setRenewTokenShow = createAction(
  'Set Renew Token Show',
  props<{value: boolean}>()
);

