<section>
  <div class="row">
    <!-- left-side Graphics -->
    <div class="col-4" style="position: relative; top: 40px">
      <div class="bg-vrt-line-3"></div>
      <div
        class="icon-box"
        style="top: -45px"
        [class.inactive]="orderStep !== 1"
      >
        <i class="siemens-shopping-cart"></i>
        <div>{{ textFetch("Order_confirmOrder") }}</div>
      </div>
      <div
        class="icon-box"
        style="top: 134px"
        [class.inactive]="orderStep !== 2"
      >
        <i class="siemens-email"></i>
        <div>{{ textFetch("Order_software") }}</div>
      </div>
    </div>

    <!-- Text fields -->
    <div class="col-8">
      <!-- Confirm Order Section -->
      <div *ngIf="orderStep === 1">
        <div class="spacing">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 1/2</span>
          <h6>{{ textFetch("Order_confirmOrder") }}</h6>
        </div>
        <div class="spacing">{{ textFetch("Order_intro") }}</div>
        <div>
          {{ textFetch("Order_description_1") }}
          <a
            href="https://wiki.siemens.com/display/SiemensCA/Virtual+Smart+Card"
            class="fakeLink"
            target="_blank"
            style="text-decoration: underline"
          >
            {{ textFetch("Here") }}
          </a>
        </div>
        <!-- ORDER COSTS -->
        <div *ngIf="userPrices == null">
          <i>{{ textFetch("Order_priceFetchError")}}</i>
        </div>
        <div class="spacing" *ngFor="let item of userPrices | keyvalue">
          <div *ngIf="item.value === ''; else elseBlock">
            <b>{{ item.key }}: {{ textFetch("Order_CCA") }}</b>
          </div>
          <ng-template #elseBlock>
            <b>{{ item.key }}: {{ item.value }} {{ textFetch("Order_costs") }}</b>
          </ng-template>
        </div>
        <!-- CHECKBOX -->
        <div class="form-check spacing">
          <input
            class="form-check-input"
            type="checkbox"
            (change)="order = !order"
          />
          <label for="cb-enabled" class="form-check-label">
            {{ textFetch("Order_checkbox") }}
          </label>
        </div>
        <button
          type="button"
          class="btn btn-hero spacing"
          *ngIf="order"
          (click)="ordering()"
        >
          {{ textFetch("Order_checkboxLabel") }}
        </button>
      </div>

      <!-- Receive Email Section -->
      <div *ngIf="orderStep === 2">
        <div class="spacing">
          <span style="color: #00ffb9">{{ textFetch("Step") }} 2/2</span>
          <h6>{{ textFetch("Order_software") }}</h6>
        </div>
        <div class="spacing">
          {{ textFetch("Order_email_1") }}<br />
        </div>
        <div class="spacing">
          {{ textFetch("Redirecting") }} {{ time.count }} {{ textFetch("Seconds") }}
        </div>
      </div>
    </div>
  </div>
</section>
